import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { ConsultingComponent } from './components/pages/consulting/consulting.component';
import { ImplementationComponent } from './components/pages/implementation/implementation.component';
import { CloudDevopsComponent } from './components/pages/cloud-devops/cloud-devops.component';
import { HumanCapitalComponent } from './components/pages/human-capital/human-capital.component';
import { PolicyComponent } from './components/pages/policy/policy.component';
import { TermsconditionsComponent } from './components/pages/termsconditions/termsconditions.component';
import { MaintenanceComponent } from './components/pages/maintenance/maintenance.component';

const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'about', component: AboutComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'careers', component: CareersComponent },
    { path: 'consulting', component: ConsultingComponent },
    { path: 'implementation', component: ImplementationComponent },
    { path: 'cloud-devops', component: CloudDevopsComponent },
    { path: 'human-capital', component: HumanCapitalComponent },
    { path: 'policy', component: PolicyComponent },
    { path: 'termsconditions', component: TermsconditionsComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '**', component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }