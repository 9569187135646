<div class="service-title service-title-bg bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Service Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Maintenance
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    
                    <div class="service-list">
                        <p>Services</p>                            
                        <ul>
                            <li>
                                <a routerLink="/consulting">
                                    Consulting 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/implementation">
                                    Implementation 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/cloud-devops">
                                    Cloud & DevOps Automation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/maintenance">
                                   Maintenance
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/human-capital">
                                    Human Capital
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-8">
                <h4>Maintenance</h4>
                <img src="assets/img/service/5.PNG" alt="service">
                <div class="service-details-post">
                    
                    <p>Vevi Solutions adopts a state-of-the-art approach which includes planning, analysis & design, installation, configuration, deployment, and support. We also provide additional services such as on-call production support, proactive maintenance, bug fixing, enhancement, user support, and training. In short, we manage the entire life cycle of application maintenance.</p>

                    <p>With two decades of Application Development & maintenance experience, we have enlisted qualified specialists who provide predictable results and high, consistent service quality. With an innovative approach to application management, we envisage practical edgy application support and maintenance.</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Corrective Maintenance
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Perfective Maintenance
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Adaptive Maintenance
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Preventive Maintenance
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Emergency Maintenance
                        </li>
                    </ul>

                    <p>With bespoke maintenance services, free in-house IT resources to focus on core business, improve response and resolution timeframes, and archives of new products and upgrades to customers</p>
                    
                </div>
            </div>
        </div>
    </div>
</div>