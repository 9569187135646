<div class="blog-slider owl-carousel owl-theme">
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/1.png" alt="blog image">
        </div>
        <div class="blog-text">
            <h4>Why the internet of things needs 5G Connectivity?</h4>
            <ul>
                <li>
                    <i class="icofont-calendar"></i>
                    20 Feb 2022
                </li>
                <li>
                    <i class="icofont-ui-user"></i>
                    John
                </li>
            </ul>

            <p>5G is the fifth generation of cellular technology for network communications. Mobile phone operators
                started to deploy it in late 2019 in select cities, and more are being added in 2020. Compared to 4G
                LTE, 5G will have up to 100 times the speed and 10 times less latency. Theoretical speeds for 5G
                downlinks can go up to 20 Gbps, and 10 Gbps for uplinks. However, real-world speeds will be up to 100
                Mbps to download and 50 Mbps to upload. Latency in connecting to the network from a device will
                typically be 4 milliseconds under ideal conditions, but critical applications such as remote surgery
                will enjoy latency as low as 1 millisecond.</p>
            <div #collapse1="ngbCollapse" [(ngbCollapse)]="isCollapsed1">
                <p>In addition, 5G will enable many more simultaneous connections to devices in the Internet of Things
                    (IoT), such as sensors in manufacturing plants, industrial control systems in power plants, in-car
                    Wi-Fi, video doorbells, and smart thermostats. But with all these additional connections to IoT
                    devices, the attack surface will increase by an order of magnitude. So, we'll need to increase
                    security with 5G, and we'll need to build security by design for these 5G-enabled IoT devices.</p>
            </div>
            <a (click)="collapse1.toggle()" [attr.aria-expanded]="!isCollapsed1" class="blog-btn" *ngIf="isCollapsed1">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse1.toggle()" [attr.aria-expanded]="!isCollapsed1" class="blog-btn" *ngIf="!isCollapsed1">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/2.png" alt="blog image">
        </div>
        <div class="blog-text">
            <h4>How Artificial Intelligence is Changing the E-Commerce Industry?</h4>
            <ul>
                <li>
                    <i class="icofont-calendar"></i>
                    5 Mar 2020
                </li>
                <li>
                    <i class="icofont-ui-user"></i>
                    Amar
                </li>
            </ul>

            <p>Artificial Intelligence is a method of making a computer, a computer-controlled robot, or software
                think intelligently like the human mind. AI is accomplished by studying the patterns of the human brain
                and by analyzing the cognitive process. The outcome of these studies develops intelligent software and
                systems.</p>
            <div #collapse2="ngbCollapse" [(ngbCollapse)]="isCollapsed2">
                <p>AI systems work by merging large with intelligent, iterative processing algorithms. This combination
                    allows AI to learn from patterns and features in the analyzed data. Each time an Artificial
                    Intelligence system performs a round of data processing, it tests and measures its performance and
                    uses the results to develop additional expertise.</p>
                <p>Artificial intelligence (AI) makes it possible for machines to learn from experience, adjust to new
                    inputs and perform human-like tasks. Most AI examples that you hear about today - from chess-playing
                    computers to self-driving cars - rely heavily on deep learning and natural language processing.</p>
                <p>Machine learning gives AI the ability to learn. This is done by using algorithms to discover patterns
                    and generate insights from the data they are exposed to. </p>
            </div>
            <a (click)="collapse2.toggle()" [attr.aria-expanded]="!isCollapsed2" class="blog-btn" *ngIf="isCollapsed2">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse2.toggle()" [attr.aria-expanded]="!isCollapsed2" class="blog-btn" *ngIf="!isCollapsed2">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/3.png" alt="blog image">
        </div>
        <div class="blog-text">
            <h4>3 Reasons Proving the Importance of Automation</h4>
            <ul>
                <li>
                    <i class="icofont-calendar"></i>
                    10 Aug 2020
                </li>
                <li>
                    <i class="icofont-ui-user"></i>
                    Alex
                </li>
            </ul>

            <p>Automation is the process of creating software and systems to replace repeatable processes and reduce
                manual intervention. It is a way to minimize errors during the software development process, make it
                faster and more cost-efficient, and improve team collaboration and productivity.</p>
            <div #collapse3="ngbCollapse" [(ngbCollapse)]="isCollapsed3">
                <p>There are a few advantages of Automation are Testing period is shortened- Test cases will perform as
                    often as needed 24/7. Automated test cases can be conducted much faster than manual tests. Testing
                    Accuracy is increased - Automated tests have expansive databases! All test results are recorded and
                    compared with earlier ones. The development team receives very accurate automatic reports that are
                    quickly issued, whereas testers must produce reports themselves when performing manual testing. Bugs
                    are corrected before the production stage- Development team can find all the bugs easily and then ca
                    fix them as soon as possible. Cost Efficiency is improved- Detecting and fixing bugs and errors
                    still account for a significant proportion of software development costs. Now more than ever, it is
                    critical for companies to implement advanced testing strategies to quickly identify and fix errors.
                </p>
            </div>
            <a (click)="collapse3.toggle()" [attr.aria-expanded]="!isCollapsed3" class="blog-btn" *ngIf="isCollapsed3">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse3.toggle()" [attr.aria-expanded]="!isCollapsed3" class="blog-btn" *ngIf="!isCollapsed3">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/4.png" alt="blog image">
        </div>
        <div class="blog-text">
            <h4>Docker Vs, Kubernetes - Which one is better?</h4>
            <ul>
                <li>
                    <i class="icofont-calendar"></i>
                    2 Sep 2020
                </li>
                <li>
                    <i class="icofont-ui-user"></i>
                    Larry
                </li>
            </ul>

            <p>Docker is an open-source suite of software development tools for creating, sharing and running a package
                as individual containers; Kubernetes is a system for operating containerized applications at scale.</p>
            <div #collapse4="ngbCollapse" [(ngbCollapse)]="isCollapsed4">
                <p>Think of containers as standardized packaging for microservices with all the needed application code
                    and
                    dependencies inside. Creating these containers is the domain of Docker. A container can run
                    anywhere, on
                    a laptop, in the cloud, on local servers, and even on edge devices.</p>

                <p>A modern application consists of many containers. Operating them in production is the job of
                    Kubernetes.
                    Since containers are easy to replicate, applications can auto-scale: expand or contract processing
                    capacities to match user demands.</p>

            </div>
            <a (click)="collapse4.toggle()" [attr.aria-expanded]="!isCollapsed4" class="blog-btn" *ngIf="isCollapsed4">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse4.toggle()" [attr.aria-expanded]="!isCollapsed4" class="blog-btn" *ngIf="!isCollapsed4">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/5.png" alt="blog image">
        </div>
        <div class="blog-text">
            <h4>IOT Compliance and its different aspects</h4>
            <ul>
                <li>
                    <i class="icofont-calendar"></i>
                    19 May 2022
                </li>
                <li>
                    <i class="icofont-ui-user"></i>
                    Ben
                </li>
            </ul>

            <p>There's no doubt that our world is quickly moving towards connected things that operate smartly over the
                internet. With significant advancements in processors, sensors, hardware, wireless connectivity ICs, and
                edge computing, IoT software development is rapidly gaining ground for various exciting applications
                such as smart homes, smart offices, smart factories, connected health, connected logistics,
                transportation, smart grids, and many more. In 2019, the global internet of things (IoT) market was
                valued at $690 billion.</p>
            <div #collapse5="ngbCollapse" [(ngbCollapse)]="isCollapsed5">
                <p>Internet of Things work is the interaction between sensors, devices, gateways, servers, and user
                    applications. But there are IoT standards and protocols to follow to make an IoT system function and
                    transfer information in the online mode only when devices are safely connected to a communication
                    network. General protocols used for personal computers, smartphones, or tablets may have IoT device
                    constraints such as bandwidth, range, and power consumption, which is why multiple IoT network
                    protocols have been developed and new ones are still evolving. In addition to wired (USB, Serial,
                    Ethernet, etc.) and wireless (Wi-Fi, BT, Zigbee, LoRA, etc.) protocols, IoT systems have multiple
                    IoT protocols that typically have low transmission overheads like MQTT, CoAP, AMQP, XMPP or UDP.
                </p>
            </div>
            <a (click)="collapse5.toggle()" [attr.aria-expanded]="!isCollapsed5" class="blog-btn" *ngIf="isCollapsed5">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse5.toggle()" [attr.aria-expanded]="!isCollapsed5" class="blog-btn" *ngIf="!isCollapsed5">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/6.png" alt="blog image">
        </div>
        <div class="blog-text">
            <h4>System Hardening - Best Practices and industry standards</h4>
            <ul>
                <li>
                    <i class="icofont-calendar"></i>
                    1 Jun 2020
                </li>
                <li>
                    <i class="icofont-ui-user"></i>
                    Harshal
                </li>
            </ul>

            <p>Cloud computing allows businesses to store and share data securely. It also helps organizations develop,
                deploy and scale applications quickly while accessing work across teams in real-time. For these reasons,
                businesses are starting to shift from on-premises to cloud-based software development models.</p>
            <div #collapse6="ngbCollapse" [(ngbCollapse)]="isCollapsed6">
                <p>The Advantages of cloud computing are Scalability- For example, being able to easily (and quickly) scale
                    an IT solution is something that can have an immediate and far-reaching impact on business. Cost
                    efficient- In-house infrastructure requires significant expenses. Cloud hosting cost much less.
                    Level of control. If you need full control over hardware and software, you should have your own
                    infrastructure. In the case of cloud hosting, you don't have access to hardware and some operations.
                </p>
                <p>Disadvantages of Cloud is Needed security. You can better protect your data when everything is
                    located within your company. Cloud data storage and processing don't guarantee full protection:
                    there can be breaches and data leakage, an issue most relevant to public clouds. However, the
                    question is debatable because private cloud providers are taking significant steps toward improving
                    data protection.</p>
            </div>
            <a (click)="collapse6.toggle()" [attr.aria-expanded]="!isCollapsed6" class="blog-btn" *ngIf="isCollapsed6">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse6.toggle()" [attr.aria-expanded]="!isCollapsed6" class="blog-btn" *ngIf="!isCollapsed6">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>
    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/7.png" alt="blog image">
        </div>

        <div class="blog-text">
            <h4>Product Development</h4>
            <ul>
                <li><i class="icofont-calendar"></i> 16 Nov 2022</li>
                <li><i class="icofont-ui-user"></i> Srini</li>
            </ul>
            <p>Software Product development is the process of creating a new product with some added value and features.
                It can be anything from developing an entirely new product, upgrading an existing product, or improving
                the process, technique, or development methodology of the system
                Software development matters because of Business Process Optimization, which Offers a competitive edge
                and customized solutions.
            </p>
            <div #collapse7="ngbCollapse" [(ngbCollapse)]="isCollapsed7">
                <p>States of Software product development are Ideation, Conceptualization and Analysis, Product
                    Architecture, Design and Development, Quality Assurance, Training and Transfer, and Sustainable
                    Product Engineering.</p>
            </div>
            <a (click)="collapse7.toggle()" [attr.aria-expanded]="!isCollapsed7" class="blog-btn" *ngIf="isCollapsed7">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse7.toggle()" [attr.aria-expanded]="!isCollapsed7" class="blog-btn" *ngIf="!isCollapsed7">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>

    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/11.png" alt="blog image">
        </div>

        <div class="blog-text">
            <h4>Product Migration Explained</h4>
            <ul>
                <li><i class="icofont-calendar"></i> 6 Apr 2022</li>
                <li><i class="icofont-ui-user"></i> Ganesh</li>
            </ul>
            <p>Software migration will be done when the technology on which the system is developed is no longer
                supported or when it became too expensive and resource-consuming to support or when the system does
                not correspond to the renovated business process or when the system cannot be integrated with the
                modern tools.</p>

            <div #collapse8="ngbCollapse" [(ngbCollapse)]="isCollapsed8">
                <p>Software migration is like rebuilding the system from scratch, implementing the same features and
                    functionalities with modern developing technologies.</p>
                <p>Migrating the project requires a Plan, Test Environment, Redesign, Modules Installation and
                    development of Additional functionality, Data Migration, Publish Planning and Database
                    Synchronization, and finally releasing the code to production.</p>
            </div>
            <a (click)="collapse8.toggle()" [attr.aria-expanded]="!isCollapsed8" class="blog-btn" *ngIf="isCollapsed8">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse8.toggle()" [attr.aria-expanded]="!isCollapsed8" class="blog-btn" *ngIf="!isCollapsed8">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>

    <div class="blog-area">
        <div class="blog-img">
            <img src="assets/img/blog/9.png" alt="blog image">
        </div>

        <div class="blog-text">
            <h4>The DevOps World</h4>
            <ul>
                <li><i class="icofont-calendar"></i> 20 Dec 2022</li>
                <li><i class="icofont-ui-user"></i> Mike</li>
            </ul>
            <p>The DevOps methodology promotes improved, continuous communication, cooperation, integration,
                visibility, and transparency between IT operations teams (Ops) and their counterparts in the
                application development teams (Dev). </p>

            <div #collapse9="ngbCollapse" [(ngbCollapse)]="isCollapsed9">
                <p>DevOps which stands for “Development” and “Operations”, that promotes faster, better application
                    development and faster release of new or revised software features or products to customers.</p>
                <p>A core value of DevOps is customer satisfaction and faster value delivery, in addition to efforts to
                    break down barriers to communication and collaboration between development and IT operations teams.
                    DevOps is also intended to drive business innovation and continuous process improvement.
                    Benefits of DevOps is Speed, Rapid Delivery, Reliability, Improved collaboration, and Security.</p>
                The DevOps workflow consists of phases:
                <ul>
                    <li>Planning</li>
                    <li>Building</li>
                    <li>Testing and deploying</li>
                    <li>Delivering</li>
                    <li>Monitoring and logging</li>
                    <li>Gathering</li>
                </ul>

            </div>
            <a (click)="collapse9.toggle()" [attr.aria-expanded]="!isCollapsed9" class="blog-btn" *ngIf="isCollapsed9">
                Read more
                <i class="icofont-rounded-right"></i>
            </a>
            <a (click)="collapse9.toggle()" [attr.aria-expanded]="!isCollapsed9" class="blog-btn" *ngIf="!isCollapsed9">
                Read less
                <i class="icofont-rounded-left"></i>
            </a>
        </div>
    </div>


</div>