<div class="service-title service-title-bg bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Service Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Cloud & DevOps Automation
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">

                    <div class="service-list">
                        <p>Services</p>
                        <ul>
                            <li>
                                <a routerLink="/consulting">
                                    Consulting
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/implementation">
                                    Implementation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/cloud-devops">
                                    Cloud & DevOps Automation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/maintenance">
                                    Maintenance
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/human-capital">
                                    Human Capital
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div class="col-lg-8">
                <h4>Cloud & DevOps Automation</h4>
                <img src="assets/img/service/3.png" alt="service">
                <div class="service-details-post">

                    <p>We understand DevOps in the context of your business and help you leverage this approach for
                        optimal results with single click deployment, continuous integration, automated security alerts,
                        easy code management and review, and on-premise, public or hybrid cloud deployment.</p>

                    <p>We offer DevOps consulting services to our customers with services encompassing several
                        capabilities, including iterative and incremental development, on-demand workload management,
                        lightweight architecture, security, and automated testing techniques.Measuring your systems and
                        digital business effect is key to success when adopting DevOps practices. We operate the
                        following DevOps automation tools and platforms to ensure a successful DevOps journey</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Assessment and Planning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Pilot Framework Creation
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Process Implementation
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            CI/CD Pipeline
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Process Automation
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Security Integration
                        </li>
                    </ul>

                    <p>Our solid and progressive partnerships with industry-leading vendors, advanced technology
                        expertise, and customer-centric work culture enable us to deliver a competitive advantage to
                        each of our clients.</p>

                </div>
            </div>
        </div>
    </div>
</div>