<div class="about-title about-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="about-title-text">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="process-style-two" style="padding-top: 40px;">
    <div class="container">
        <div class="blue-title pb-70">
            <h2>About <span>Us</span></h2>
            <p style="text-align: justify;">Vevi Solutions offers a comprehensive range of cloud services and technology solutions built to solve real-world problems for businesses spanning different sizes, industries, and geographies.
            Our solid and progressive partnerships with industry-leading vendors, advanced technology expertise, and customer-centric work culture enable us to deliver a competitive advantage to each of our clients.
            We hold profound experience and expertise in providing solutions for complex IT implementation projects and integrating emerging technologies in a dynamic environment. Our cutting-edge services made us a seamless extension of our clients. With our dynamic, scalable, resilient, and responsive offerings, we ensure rapid growth and high value for our clients.</p>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="about-img">
                    <img src="assets/img/about-two.png" alt="about image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">   
                    <div class="section-head">
                        <h2>Core Values</h2>
                        <p>Vevi Solutions adheres to a set of core values in all business dealings. All our employees are expected to take ownership of these values and incorporate them into their everyday activities.</p>
                    </div>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Diligence
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Empathy   
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Excellence
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Ingenuity
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Verity
                        </li>
                    </ul>

                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="process-section pb-70" style="padding-top: 60px;">
    <div class="container" style="padding-bottom: 60px;">
        <div class="section-head text-center">
            <h2>Why <span>Choose Us</span></h2>
            <p>We can help you strengthen foundations, sharpen direction and improve execution. we have been supporting businesses in improving their efficiency with the help of cost engineering enabling them to position themselves on a more secure footing in the future.</p>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Above & Beyond</h3>
                    <p>
                        Quality matters. We've got high standards and it shows. Every project counts, no matter how small. Whatever problem we're tackling, we go beyond the brief.
                    </p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Thoughtful</h3>
                    <p>
                        We believe in taking the time to pause and reflect. We care deeply about our clients—who they are and what they're trying to achieve. 
                    </p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Pragmatic</h3>
                    <p>
                        Our beliefs are idealistic. But as we work to put those beliefs into practice, we're grounded in a clear-eyed assessment of what's practically possible. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-section pt-100 pb-70 mb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>We are <span>Partners</span> in Transformation</h2>
                    <div class="theme-button">
                        <a routerLink="/contact" class="default-btn">Contact Us</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">1</span>K</h2>
                                <p>Job Done</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">989</span></h2>
                                <p>Happy Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">45</span></h2>
                                <p>Experts</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">12</span></h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</div>

