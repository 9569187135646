<div class="contact-title careers-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="contact-title-text">
                    <h2>Careers</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Careers
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-post-top pt-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <h2>Carrers at <span>Vevi Solutions</span></h2>
            </div>
        </div>
    </div>
</div>

<div class="main-blog-post">
    <div class="container">
        <div class="blog-slider owl-carousel owl-theme">
            <div class="blog-area" *ngFor="let job of jobData">
                <div class="blog-text">
                    <h4>{{job.title}}</h4>
                    <ul>
                        <li>
                            <i class="icofont-location-pin"></i>
                            {{job.location}}
                        </li>
                    </ul>
                    <p>{{job.jobDesc}}</p>
                    <div class="theme-button">
                        <a routerLink="/contact" class="default-btn">Contact</a>
                    </div>
                </div>
            </div>
        </div>
    </div>