<div class="blog-title blog-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Our Blogs</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Blogs
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-post-top pt-100">
    <div class="container">
        <div class="row pb-65 align-items-center">
                <h2>Our Latest <span>Blogs</span></h2>
        </div>
    </div>
</div>

<div class="main-blog-post">
    <div class="container">
        <app-blogs></app-blogs>

    </div>
</div>