import { Component } from '@angular/core';
import { CareersService, Job } from 'src/app/services/careers.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent {

  jobData: Array<Job> = [];
  constructor(private service: CareersService) { }
  ngOnInit() {
    this.service.getJobsData()
      .subscribe((response: Job[]) => {
        this.jobData = response;
      });
  }
}
