<div class="service-title service-title-bg bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Service Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Human Capital
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    
                    <div class="service-list">
                        <p>Services</p>                            
                        <ul>
                            <li>
                                <a routerLink="/consulting">
                                    Consulting 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/implementation">
                                    Implementation 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/cloud-devops">
                                    Cloud & DevOps Automation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/maintenance">
                                   Maintenance
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/human-capital">
                                    Human Capital
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-8">
                <h4>Human Capital</h4>
                <img src="assets/img/service/4.png" alt="service">
                <div class="service-details-post">
                    
                    <p>we believe that people can be a company's greatest asset. With the right plan in place, every employee has the opportunity to contribute to the company's success and move the business forward.</p>

                    <p>With the skills and experience of our Human Capital Services consultants, we'll work with your professionals to understand your HR and finance goals, and design a custom people plan that supports your business strategy.</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            HR 
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Organization 
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Workforce 
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Human Capital
                        </li>
                    </ul>

                    <p>Our solid and progressive partnerships with industry-leading vendors, advanced technology expertise, and customer-centric work culture enable us to deliver a competitive advantage to each of our clients.</p>
                    
                </div>
            </div>
        </div>
    </div>
</div>