import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ConsultingComponent } from './components/pages/consulting/consulting.component';
import { ImplementationComponent } from './components/pages/implementation/implementation.component';
import { CloudDevopsComponent } from './components/pages/cloud-devops/cloud-devops.component';
import { MaintenanceComponent } from './components/pages/maintenance/maintenance.component';
import { HumanCapitalComponent } from './components/pages/human-capital/human-capital.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { TermsconditionsComponent } from './components/pages/termsconditions/termsconditions.component';
import { PolicyComponent } from './components/pages/policy/policy.component';
import { BlogsComponent } from './components/pages/blog/blogs/blogs.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HeaderComponent,
    FooterComponent,
    HomeOneComponent,
    AboutComponent,
    BlogComponent,
    ErrorComponent,
    ContactComponent,
    ConsultingComponent,
    ImplementationComponent,
    CloudDevopsComponent,
    MaintenanceComponent,
    HumanCapitalComponent,
    CareersComponent,
    TermsconditionsComponent,
    PolicyComponent,
    BlogsComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
