<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/contact-img.png" alt="contact image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <div class="alert alert-primary" role="alert" *ngIf="alertMessage">
                                {{alertMessage}}
                            </div>
                            <form id="contactForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="text" formControlName="name" id="name" class="form-control"
                                                placeholder="Your Name">
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input type="email" formControlName="email" id="email" class="form-control"
                                                placeholder="Your Email">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea formControlName="message" class="form-control" id="message"
                                                cols="30" rows="5" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn page-btn"
                                            [disabled]="!profileForm.valid">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">

                    <p>we are a small team of highly experienced specialist Reward
                        consultants, with Analyst, Cloud and Devops Automation and additional Maintenance support where
                        required.</p>
                    <div class="alert alert-primary" role="alert" *ngIf="alertEmailMessage">
                        {{alertEmailMessage}}
                    </div>
                    <div class="email">

                        <form class="newsletter-form" [formGroup]="profileForm" (ngSubmit)="onEmail()">
                            <input type="email" class="form-control" placeholder="Enter Your Email"
                                formControlName="email">
                            <button class="default-btn electronics-btn" type="submit"><i
                                    class="icofont-location-arrow"></i></button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Services</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/consulting">Consulting</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/implementation">Implementation</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/cloud-devops">Cloud & Devops
                                Automation</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/maintenance">Maintenace</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/human-capital">Human Capital</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/about">About Us</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/blog">Blog</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/careers">Careers</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/policy">Privacy Policy</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/termsconditions">Terms Of Use</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contact Us</h3>
                    <ul class="find-us">
                        <li>
                            <i class="icofont-location-pin"></i>
                            5005 W Royal Ln
                        <li> Suite 136, Irving</li>
                        <li>Texas 75063</li>
                        <li> United States</li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+880123456789">+1-323-813-8384</a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto:contact@vevisolutions.com">contact@vevisolutions.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="https://www.facebook.com/people/Vevi-Solutions/100088035978913/"
                                    target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://twitter.com/vevisolutions" target="_blank"><i
                                        class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/vevi-solutions/" target="_blank"><i
                                        class="icofont-linkedin"></i></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/vevisolutions"><i
                                        class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>Copyright ©2023 VeVi Solutions Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>
