<div class="service-title service-title-bg bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Service Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Consulting
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    
                    <div class="service-list">
                        <p>Services</p>                            
                        <ul>
                            <li>
                                <a routerLink="/consulting">
                                    Consulting 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/implementation">
                                    Implementation 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/cloud-devops">
                                    Cloud & DevOps Automation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/maintenance">
                                   Maintenance
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/human-capital">
                                    Human Capital
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-8">
                <h4>Consulting</h4>
                <img src="assets/img/service/1.png" alt="service">
                <div class="service-details-post">
                    
                    <p>Our consulting services focus on our clients' most critical issues and opportunities: strategy, marketing, organization, operations, technology, transformation, digital, advanced analytics, corporate finance, mergers & acquisitions and sustainability across all industries and geographies.We have proven a multiplier effect from optimizing the sum of the parts, not just the individual pieces.</p>

                    <p>We believe open ecosystems, open technologies, open innovation and open cultures are the key to creating opportunities and the way forward for modern business and for our world.We want to work together, create together, grow together and rethink what's possible together.</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Strategy
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Technology
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Experience
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Operations
                        </li>
                    </ul>

                    <p>Accelerate your innovation and transformation with a fully integrated suite of capabilities that puts digital at the heart of everything you do.</p>
                    
                </div>
            </div>
        </div>
    </div>
</div>