<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-location-pin"></i> 5005 W Royal Ln, Suite 136, Irving, Texas 75063</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+880123456789">+1-323-813-8384</a></li>
                        <li><i class="icofont-email"></i> <a href="mailto:contact@vevisolutions.com">contact@vevisolutions.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/people/Vevi-Solutions/100088035978913/"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="https://twitter.com/vevisolutions"><i class="icofont-twitter"></i></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/vevi-solutions/"><i class="icofont-linkedin"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/vevisolutions"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/consulting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Consulting</a></li>

                                <li class="nav-item"><a routerLink="/implementation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Implementation</a></li>

                                <li class="nav-item"><a routerLink="/cloud-devops" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cloud & DevOps Automation</a></li>

                                <li class="nav-item"><a routerLink="/maintenance" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Maintenance</a></li>

                                <li class="nav-item"><a routerLink="/human-capital" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Human Capital</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                        <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers</a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
