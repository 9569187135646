<div class="home-section">
    <div class="home-slider-area owl-carousel owl-theme">
        <div class="home-slider-item items-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>We Help Business Innovate and Grow</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>We Imagine Design through Technology</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-slider-item items-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Smaller changes make a huge difference</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-slider-item items-bg4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Team Work</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-slider-item items-bg5">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Work-life balance</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-slider-item items-bg6">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Planning ahead</h1>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="process-section pb-70" style="padding-top: 60px;">
    <div class="container" style="padding-bottom: 60px;">
        <div class="section-head text-center">
            <h2>Why <span>Choose Us</span></h2>
            <p>We can help you strengthen foundations, sharpen direction and improve execution. we have been supporting
                businesses in improving their efficiency with the help of cost engineering enabling them to position
                themselves on a more secure footing in the future.</p>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Above & Beyond</h3>
                    <p>
                        Quality matters. We've got high standards and it shows. Every project counts, no matter how
                        small. Whatever problem we're tackling, we go beyond the brief.
                    </p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Thoughtful</h3>
                    <p>
                        We believe in taking the time to pause and reflect. We care deeply about our clients—who they
                        are and what they're trying achieve.
                    </p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Pragmatic</h3>
                    <p>
                        Our beliefs are idealistic. But as we work to put those beliefs into practice, we're grounded in
                        a clear-eyed assessment of what's practically possible.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<div class="counter-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>We are <span>Partners</span> in Transformation</h2>

                    <div class="theme-button">
                        <a routerLink="/contact" class="default-btn">Contact</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">1</span>K</h2>
                                <p>Job Done</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">989</span></h2>
                                <p>Happy Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">45</span></h2>
                                <p>Experts</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">12</span></h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
<section class="blog-section pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Clients</span></h2>
            <p>Bringing decades of experience working across public and private sectors, we collaborate with clients to
                help them successfully navigate the constant change taking place in their industry.</p>
        </div>

        <div class="client-slider owl-carousel owl-theme">
            <img src="assets/img/clients/0.png" alt="blog image">
            <img src="assets/img/clients/1.png" alt="blog image">
            <img src="assets/img/clients/2.png" alt="blog image">
            <img src="assets/img/clients/3.png" alt="blog image">
            <img src="assets/img/clients/4.png" alt="blog image">
            <img src="assets/img/clients/5.png" alt="blog image">
            <img src="assets/img/clients/6.png" alt="blog image">
        </div>
    </div>
</section>

<div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Stepheny Mc.man</h2>
                    <span>Manger, AWS</span>
                    <p>Extremely approchable, with the ability to work effectively at all levels within the organization
                    </p>
                </div>

                <div class="sin-testiText">
                    <h2>Jackson</h2>
                    <span>Sr Developer, Tech Systems</span>
                    <p>In the last six months we have seen our organizational profitability increase significantly
                        thanks in large part to the change in practices around resource management and global resourcing
                        of our projects</p>
                </div>

                <div class="sin-testiText">
                    <h2>Amelia</h2>
                    <span>Lead, Infosys</span>
                    <p>The biggest reason we engaged Vevi Solutions was their knowledge and their expertise in designing
                        and building a project management office.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Latest <span>Blogs</span></h2>
            <p>Through our world-class services, we help organizations bring their native mindset into the digital
                medium using technology as an effective enabler contributing directly to the top line and bottom line.
            </p>
        </div>

        <app-blogs></app-blogs>
    </div>
</section>