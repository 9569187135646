<div class="contact-title contact-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="contact-title-text">
                    <h2>Contact Us</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="info-section">
                        <div class="info-area">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="info-text">
                                        <i class="icofont-location-pin"></i>
                                        <h6>Corporate Headquarters</h6>
                                        <p>5005 W Royal Ln <br/> Suite 136, Irving <br/>Texas 75063<br/> United States</p>
                                    </div>
                                </div>
                
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="info-text">
                                        <i class="icofont-phone"></i>
                                        <h6>Call</h6>
                                        <p>+1-323-813-8384</p>
                                    </div>
                                </div>
                
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="info-text">
                                        <i class="icofont-ui-message"></i>
                                        <h6>Message Us</h6>
                                        <p>contact@vevisolutions.com</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.0868498063137!2d-97.0161403240093!3d32.92230354939544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2a2ddd73f079%3A0xa6c7bfae6018f9e!2s5005%20W%20Royal%20Ln%20%23136%2C%20Irving%2C%20TX%2075063!5e0!3m2!1sen!2sus!4v1672418576888!5m2!1sen!2sus" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</div>