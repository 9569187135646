import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  profileForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', [Validators.email, Validators.required]),
    message: new FormControl('')
  });
  alertMessage: string = '';
  alertEmailMessage: string = '';
  constructor(private email: EmailService) { }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      name: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.required]),
      message: new FormControl('')
    });
  }

  onSubmit() {
    const data = this.profileForm.value;
    if (data.email) {
      //console.warn(this.profileForm);
      this.email.sendEmail(data.name, data.email, data.message)
        .subscribe({
          next: data => {
            this.alertMessage = 'Successfully sent your request.';
          },
          error: error => {
            this.alertMessage = 'Please try again!';
            console.error('There was an error!', error);
          }
        });
    }
  }

  onEmail() {
    const data = this.profileForm.value;
    if (data.email) {
      //console.warn(this.profileForm);
      this.email.sendEmail('Vevi', data.email, 'Contact')
        .subscribe({
          next: data => {
            this.alertEmailMessage = 'Successfully sent your request.';
          },
          error: error => {
            this.alertEmailMessage = 'Please try again!';
            console.error('There was an error!', error);
          }
        });
    }
  }

}
