<div class="service-title service-title-bg bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Service Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Implementation
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    
                    <div class="service-list">
                        <p>Services</p>                            
                        <ul>
                            <li>
                                <a routerLink="/consulting">
                                    Consulting 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/implementation">
                                    Implementation 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/cloud-devops">
                                    Cloud & DevOps Automation
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/maintenance">
                                   Maintenance
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/human-capital">
                                    Human Capital
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-8">
                <h4>Implementation</h4>
                <img src="assets/img/service/2.png" alt="service">
                <div class="service-details-post">
                    
                    <p>Our Implementation services offer end-to-end IT capabilities including strategy, planning, design, implementation, and ongoing management of your applications and/or infrastructure, including support for hybrid IT models across on-premise, third-party data centers, and the cloud.</p>

                    <p>We scale, adjust, and implement carefully monitored solutions in cost-effective, manageable stages to include expert insights and robust recommendations that strengthen your decision-making, in alignment with your business & technology strategies. Our consulting and implementation services include Data Center Assessment, Data Center as a Service (DCaaS), Data Center Consolidation, Data Center Migration, Infrastructure Reengineering, Cloud Assessment & Advisory, Cyber Security Assessment, to name a few.</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Consulting
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Data Migration
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Training
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Security
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Data Analytics
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Digital Transfromation
                        </li>
                    </ul>

                    <p>Our proven methodology takes into account each client's unique strategic objectives, processes, culture and systems-integration needs. We have accumulated extensive knowledge of best practices in each of our core focus areas, and we have found that success inevitably depends on the degree to which these best practices are followed. When properly deployed, we know from experience that our clients' customer-centric programs can achieve sustainable user adoption and produce a significant return on investment.</p>
                    
                </div>
            </div>
        </div>
    </div>
</div>