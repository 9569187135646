import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CareersService {

  private url = 'assets/jobsData.json';

  constructor(private httpClient: HttpClient) { }

  getJobsData() {
    return this.httpClient.get<Job[]>(this.url);
  }
}

export interface Job {
  title?: string;
  jobDesc?: string;
  jobId?: string;
  location?: string;
}
