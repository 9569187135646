import { Component } from '@angular/core';

@Component({
  selector: 'app-human-capital',
  templateUrl: './human-capital.component.html',
  styleUrls: ['./human-capital.component.scss']
})
export class HumanCapitalComponent {

}
