import { Component } from '@angular/core';

@Component({
  selector: 'app-cloud-devops',
  templateUrl: './cloud-devops.component.html',
  styleUrls: ['./cloud-devops.component.scss']
})
export class CloudDevopsComponent {

}
