import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  uri: string = 'https://vevi-website-func.azurewebsites.net/api/HttpTrigger?code=t3qYKSiPK2srjWi-lTrdoAfm0Mzik38ffukRKj3rhrHoAzFuvHNkEw=='
  constructor(private httpClient: HttpClient) { }

  sendEmail(name: string, email: string, message: string): Observable<any> {
    const emailParams = {
      "name": name,
      "email": email,
      "message": message
    }
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.httpClient.post<any>(this.uri, emailParams, { headers: headers });
  }
}
